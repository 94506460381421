@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/inter-v3-latin-ext-regular.ef974517.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-regular.ac41b38a.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/inter-v3-latin-ext-500.c72839d9.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-500.1c9c3f79.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/inter-v3-latin-ext-600.e84b862c.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-600.a4d12afc.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/inter-v3-latin-ext-700.4ef09ca6.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-700.11d55ccc.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v27-latin-700.40de70ad.woff2") format("woff2"), url("../fonts/roboto-v27-latin-700.2a7a95d3.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/dm-mono-v5-latin-ext-regular.ee460864.woff2") format("woff2"), url("../fonts/dm-mono-v5-latin-ext-regular.0c115929.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/dm-mono-v5-latin-ext-500.ee966a3f.woff2") format("woff2"), url("../fonts/dm-mono-v5-latin-ext-500.a4b84b3e.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Alliance;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/Alliance-SemiBold.f50d1d29.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Literata;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Literata-VariableFont.8607a2c9.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Inconsolata-VariableFont.a2e90248.woff2") format("woff2");
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

/*# sourceMappingURL=index.3527160e.css.map */
